// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { MosaicModuleAddonType } from './Mosaic.contracts'

export const MosaicModuleAddonTypeFormComponentRegistry: Record<MosaicModuleAddonType, AsyncComponent> = {
  [MosaicModuleAddonType.Embed]: () => import(
    /* webpackChunkName: "cms/EmbedAddonForm" */
    './addons/Embed/Embed.form.vue'
  ),
  [MosaicModuleAddonType.Image]: () => import(
    /* webpackChunkName: "cms/ImageAddonForm" */
    './addons/Image/Image.form.vue'
  )
}

export const MosaicModuleAddonTypeUiComponentRegistry: Record<MosaicModuleAddonType, AsyncComponent> = {
  [MosaicModuleAddonType.Embed]: () => import(
    /* webpackChunkName: "cms/EmbedAddonUi" */
    './addons/Embed/Embed.ui.vue'
  ),
  [MosaicModuleAddonType.Image]: () => import(
    /* webpackChunkName: "cms/ImageAddonUi" */
    './addons/Image/Image.ui.vue'
  )
}
